<template>
  <a-modal
    :visible="showModalEdit"
    :footer="null"
    :width="490"
    @cancel="$emit('handleShowModal','close',false), loading = false"
  >
    <a-row>
      <!-- <a-col class="d-flex justify-content-center">
        <div
          v-if="showModalDelBusiness || showModalSuccesChangePass"
          style="
              width: 180px;
              height: 180px;
              background: linear-gradient(153.43deg, #F49685 0%, #EC4426 83.33%);
              border-radius: 50%;
              margin-bottom: 40px"
        >
          <TrashIcon v-if="showModalDelBusiness" style="width: 180px; height:auto; color: #ffffff" />
          <Checklist v-if="showModalSuccesChangePass" style="width: 180px; height:auto; color: #ffffff; position: absolute; top: 41px; right: 112px;" />
        </div>
      </a-col> -->
      <a-col class="d-flex">
        <div class="pbz-font subtitle-md-medium">
          {{ captionTitle() }}
        </div>
      </a-col>
      <a-col class="d-flex justify-content-center mt-5 mb-5">
        <div>
          <img
            :src="rowData.sales_channel && rowData.sales_channel.logo_url"
            width="120"
            height="50"
          >
        </div>
      </a-col>
      <a-col :span="24" class="my-3" style="color: #1A1A1A">
        <a-input
          v-model="namaToko"
          style="border: 1px solid #CCCCCC !important; height: 48px !important"
          placeholder="Nama Toko"
          @input="handleInputEmail"
        />
        <label class="label-form">Nama Toko</label>
      </a-col>
      <a-col :span="24" class="my-3" style="color: #1A1A1A">
        <a-select
          v-model="status"
          placeholder="Pilih Gudang"
          style="width: 100%;"
          class="select-role"
        >
          <a-select-option v-for="(item, index) in statusList" :key="index" :value="item.value">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <label class="label-form">Pilih Gudang</label>
      </a-col>
      <a-col :span="24" class="d-flex justify-content-center" style="margin: 10px 0px">
        <div class="pbz-font" style="width: 60%; text-align:center; color:#999999">
          {{ captionModal() }}
        </div>
        <div v-if="showModalHistoryUser" class="col-12" style="width: 370px; height: 370px; overflow: auto">
          <ul v-for="(item, index) in dataHistory" :key="index">
            <li>{{ $moment(item.created_at).format('DD MMM YYYY HH:MM') }}</li>
            <span> {{ item.event }} </span>
            <p> {{ item.description }} </p>
          </ul>
        </div>
      </a-col>
      <a-col :span="24" class="d-flex justify-content-center" style="margin-top: 50px">
        <a-button
          class="pbz-font"
          style="
                border: 1px solid #E00000;
                background: #E00000;
                color: #FFFFFF;
                width: 100%;
                height: auto;
                padding: .75rem;
                border-radius: 5px;
                cursor: pointer;
            "
          @click.prevent="submitButton()"
        >
          <div v-if="!loading || showModalSuccesChangePass">
            {{ captionSubmit() }}
          </div>
          <div v-if="loading && !showModalSuccesChangePass">
            <a-icon type="loading" />
          </div>
        </a-button>
      </a-col>
      <a-col :span="24" class="d-flex justify-content-center align-items-center">
        <a-button
          class="pbz-font"
          style="
              color: #E00000;
              padding: .75rem;
              cursor: pointer;
              width: 100%;
              height: auto;
              border: none;
            "
          @click="() => {$emit('handleShowModal','close',false), loading = false}"
        >
          Batal
        </a-button>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
// import TrashIcon from '@/components/Icons/TrashIcon.vue'
// import Checklist from '@/components/Icons/Checklist.vue'

export default {
  name: 'ModalUser',
  // components: {
  //   TrashIcon,
  //   Checklist,
  // },
  props: {
    showModalEdit: {
      type: Boolean,
      default: () => false,
    },
    showSuccess: {
      type: Boolean,
      default: () => false,
    },
    showFailed: {
      type: Boolean,
      default: () => false,
    },
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      loading: false,
      namaToko: '',
      status: '',
      statusList: [],
      showModalHistoryUser: false,
    }
  },
  computed: {
     getPropsData() {
      return this.rowData
    },
  },
  watch: {
    getPropsData(value) {
      this.namaToko = value.title
    },
  },
  methods: {
    handleInputEmail() {

    },
    captionModal() {
      if (this.showModalEditUser) {
        return 'Apakah anda yakin akan mengubah data pengguna?'
      }
      if (this.showModalEditBusiness) {
        return 'Apakah Anda yakin akan mengubah data client Anda?'
      }
      if (this.showModalChangePassword) {
        return 'Apakah anda yakin akan melakukan perubahan ini?'
      }
      if (this.showModalDelBusiness) {
        return 'Setelah menghapus pada list client, data tersebut akan langsung terhapus'
      }
      if (this.showModalSuccesChangePass) {
        return 'Kata sandi yang Anda miliki telah berhasil di ubah'
      }
    },
    captionTitle() {
      if (this.showModalEdit) {
        return 'Ubah Informasi Toko'
      }
      if (this.showModalDelBusiness) {
        return 'Anda yakin ingin menghapus?'
      }
      if (this.showModalHistoryUser) {
        return 'Riwayat Perubahan'
      }
      if (this.showModalSuccesChangePass) {
        return 'Sukses'
      }
    },
    captionSubmit() {
      if (this.showModalEdit) {
        return 'Simpan'
      }
      if (this.showModalEditBusiness) {
        return 'Ya, Ubah Client'
      }
      if (this.showModalDelBusiness) {
        return 'Konfirmasi'
      }
      if (this.showModalChangePassword) {
        return 'Ya, Ubah Kata Sandi'
      }
      if (this.showModalSuccesChangePass) {
        return 'Kembali Ke Dashboard'
      }
    },
    submitButton() {
      this.loading = true
      if (this.showModalEditUser) {
        this.$emit('submitEditUser')
      } else if (this.showModalEditBusiness) {
        this.$emit('submitEditBusiness')
      } else if (this.showModalDelBusiness) {
        setTimeout(() => {
          this.loading = false
        }, 1000)
        this.$emit('submitDeleteBusiness')
      } else if (this.showModalChangePassword) {
        this.$emit('submitButton')
        this.loading = false
      } else if (this.showModalSuccesChangePass) {
        this.$router.push({
          name: 'dashboard',
        })
      }
    },
  },
}
</script>

<style>
.label-form {
  position: absolute;
  left: 15px;
  top: -11px;
  background: #FFFFFF;
  color: #B3B3B3;
}
</style>
