import {
  reactive,
  toRefs,
  computed,
  getCurrentInstance,
  onMounted,
} from 'vue'
import store from '@/store'
import data from '@/services/store/data'

export default (props) => {
  const $el = getCurrentInstance()
  const $root = $el.proxy.$root

  const { state: state_store, dispatch } = store

  const state = reactive({
    modalSync: false,
    modalSuccessSync: false,
    modalListMW: false,
    idData: null,
  })

  /* State of store */
  const integration = computed(() => state_store.integration)

  onMounted(() => {
    if (typeof localStorage.getItem('pathCallback') === 'string') {
      localStorage.removeItem('pathCallback')
    }
    /* if have code after sync tiktok */
    if (
      $root.$route.query?.code &&
      $root.$route.query?.['company-aggregator-id'] &&
      $root.$route.query?.name
    ) {
      const {
        ['company-aggregator-id']: aggregator_id,
        code,
        name,
        business_id,
      } = $root.$route.query
      dispatch('integration/ADDSTOREMARKETPLACE', {
        name: name,
        aggregator_id: aggregator_id,
        code: code,
        business_id: business_id,
      })
    }
  })

  /* Data marketplace */
  const dataMarketPlace = computed(() => {
    return data.filter((val) => val.type === 'store')
  })

  /* Data fulfilment */
  const dataFulfilment = computed(() => {
    return data.filter((val) => val.type === 'warehouse')
  })

  /* Detail Data for modal sync */
  const detailData = computed(() => {
    return data.find((val) => val.id === props?.idData)
  })

  /* Start Sync */
  const syncMarketPlace = async (id, name) => {
    if (id !== null) {
      let route = {
        path: $root.$route.path,
        query: $root.$route.query,
      }

      /* Sync tiktok */
      if (name === 'Tiktok') {
        route = {
          ...route,
          query: {
            ...route.query,
            'company-aggregator-id': id,
            name: name.toLowerCase(),
          },
        }
        localStorage.setItem('pathCallback', JSON.stringify(route))
        const { data } = await dispatch('integration/SYNCTIKTOK', id)
        window.location = data.data.authorized_url
      }
    }
  }

  /* Open modal sync confirm */
  const openModal = (id) => {
    state.modalSync = !state.modalSync
    state.idData = id
  }

  /* Open modal success sync confirm */
  const openModalSuccess = () => {
    state.modalSync = false
    state.modalSuccessSync = !state.modalSuccessSync
  }

  /* Open modal list marketplace & warehouse */
  const openModalMW = () => {
    state.idData = null
    state.modalListMW = !state.modalListMW
  }

  /* choice marketplace */
  const choiceMarketplace = (id) => {
    state.idData = id
  }

  return {
    dataMarketPlace,
    dataFulfilment,
    ...toRefs(state),
    openModal,
    openModalSuccess,
    openModalMW,
    choiceMarketplace,
    detailData,
    syncMarketPlace,
    integration,
  }
}
