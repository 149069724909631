<template>
  <a-modal :visible="visible" :wrap-class-name="$style.modalListMarketplace" :title="$t('sideBar.add_store')" width="650px">
    <template #closeIcon>
      <a-icon type="close" @click="openModalMW()" />
    </template>
    <label class="mr-3 mb-2 d-block">{{ $t('order.warehouse') }}</label>
    <a-select ref="select" :class="$style.selectWareHouse" style="width: 100%">
      <a-select-option value="jakarta">
        Jakarta
      </a-select-option>
      <a-select-option value="bogor">
        Bogor
      </a-select-option>
    </a-select>
    <div class="d-flex mt-4 justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <h5>{{ $t('add_store.channel_integration') }}</h5>
        <p>{{ $t('add_store.choose_chanel') }}</p>
      </div>
      <div class="d-flex">
        <a-select ref="select" style="width: 180px;">
          <a-select-option value="marketplace">
            Marketplace
          </a-select-option>
          <a-select-option value="warehouse">
            Warehouse
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="d-flex flex-wrap mt-3">
      <template v-for="(data, i) in dataMarketPlace">
        <card :key="i" :data-card="data" :active="idData" :set-active="choiceMarketplace" />
      </template>
    </div>
    <template #footer>
      <a-button class="mr-2" @click="openModalMW()">
        {{ $t('add_store.cancel') }}
      </a-button>
      <a-button type="primary" @click="openModalMW(), idData !== null && openModalSync(idData)">
        {{ $t('add_store.next') }}
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import addStore from '@/composable/store/addStore'
import Card from '@/components/Store/AddStore/Card.vue'

export default {
  name: 'ModalListMarketplaceWarehouse',
  components: {
    Card,
  },
  props: {
    visible: Boolean,
    openModalMW: Function,
    openModalSync: Function,
  },
  setup(props) {
    const {
      dataMarketPlace,
      dataFulfilment,
      idData,
      choiceMarketplace,
    } = addStore(props)


    return {
      dataMarketPlace,
      dataFulfilment,
      idData,
      choiceMarketplace,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
